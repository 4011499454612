import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";

import { PORT } from "./CONSTANTS.js";

let endpoint = PORT;
let socket = io(endpoint);

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const Chat = ({ city, first, second, name, blocked, addMessage, messages }) => {
  const [text, setText] = useState("");
  first = `${first.name}, ${first.country}`;
  second = `${second.name}, ${second.country}`;
  const playerRegion = `${first} & ${second}`;

  const sendBlockedMessage = () => {
    const message = {
      message:
        "You are blocked. You cannot send messages during this time period",
      region: playerRegion,
    };
    addMessage(message);
  };

  // handle time changes
  useEffect(() => {
    // Might be useful to uncomment later on.
    // const message = {
    //   message: `Joined ${playerRegion}'s Chat Room`,
    //   region: playerRegion,
    // };
    if (blocked) {
      sendBlockedMessage();
    }
  }, [addMessage, playerRegion, first, second, blocked]);

  const handleKeyPress = (event) => {
    if (text.trim() === "") return;
    if (blocked) return sendBlockedMessage();
    if (event.key !== "Enter") return;
    const message = {
      message: text,
      city,
      name,
      outgoing: true,
      region: playerRegion,
    };
    setText("");
    socket.emit("message", message);
    addMessage(message);
  };

  return (
    <>
      <h3 style={{ color: "#000" }}>Chat</h3>
      <ul
        className="messages"
        style={{ color: "#000", height: 500, overflowY: "scroll" }}
      >
        {messages.map((message, i) => {
          if (message.region !== playerRegion) return <></>;
          if (message.name === name && message.outgoing === false) return <></>;
          if (message.name === undefined)
            return (
              <li key={i.toString()} className="internal-message">
                {message.message}
              </li>
            );

          console.log(message);

          return (
            <li
              className={
                message.outgoing ? "outgoing-message" : "incoming-message"
              }
              key={i.toString()}
            >
              {message.outgoing
                ? "You"
                : `${message.city.name}, ${message.city.country}`}
              : {message.message}
            </li>
          );
        })}
        <AlwaysScrollToBottom />
      </ul>
      <div className="message-input">
        <input
          type="text"
          value={text}
          placeholder={
            blocked ? "You are blocked from chat" : "Type your message..."
          }
          onChange={(e) => {
            setText(e.target.value);
          }}
          onKeyPress={handleKeyPress}
          disabled={blocked}
        />
      </div>
    </>
  );
};

export default Chat;
