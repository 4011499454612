import {createStore,combineReducers} from 'redux';
import {NOT_LOGGED_IN} from './CONSTANTS.js';


const defaultState = {
    loginStatus: NOT_LOGGED_IN,
    test: 45,
}
// here is actions:
export const setLogInState = status => ({
    type: "SET_LOGIN_STATE",
    status
})
export const setTest = test => {
    return {
        type: "SET_TEST",
        test
    }
}

// here is Reducers 
export const setLogInStatus = status => {
    return {
        type: "SET_LOGIN_STATE",
        status: status
    }
}
export const setTestReducer = test => {
    return {
        type: "SET_TEST",
        test: test
    }
}
export const authReducer = (state = defaultState, action) => {
    switch(action.type) {
        case "SET_LOGIN_STATE":
            return {
                ...state,
                loginStatus: action.status
            }

        case "SET_TEST":
            return {
                ...state,
                test: action.test
            }
        default:
            return state;
    }
}

export default function() {
    let combined  = combineReducers({
        auth: authReducer
    });
    let chromeTracker = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    let store = createStore(combined,chromeTracker)
    return store;
}

// why VS CODE always f**king report syntax error here.;;;;;;;;;;;;;
