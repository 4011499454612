import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { LOGGED_IN_AS_ADMIN } from "./CONSTANTS.js";
import io from "socket.io-client";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Box from "@material-ui/core/Box";
import ChatIcon from "@material-ui/icons/Chat";
import { IconButton } from "@material-ui/core";
import ChatWindow from "./Chat";
import WorldClock from "./Worldmap";
import { withRouter, Link } from "react-router-dom";

import { PORT } from "./CONSTANTS.js";

import axios from "axios";

let endpoint = PORT;
let socket = io(endpoint);

const IDLE = 0;
const PREPARE_GAME = 1;
const ON_GAMING = 2;
const POST_GAMING = 3;

// this game uses slower minutes
// 1 min = 60 * 1.25 seconds = 75 seconds
const END_OF_DAY = 24 * 75 * 1000;
const GOODNIGHT = 0 * 75 * 1000;
const GOODMORNING = 7 * 75 * 1000;
class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalID: -1,
      mode: IDLE, // essential
      cities: [], // essential

      currentTimes: [],
      initialTimes: [],
      blocked: [],
      selfBlocked: false,
      messages: [],

      // Preparing Game mode state variables:
      countryIndexSelected: -1, // essential
      showDummyModal: false,
      showVideoModal: false,
      first: "",
      second: "",

      renderVideo: false,
      citiesForWorldMap: null,
      // bookkeeping info
      gameTimeStamp: null, // essential
      startTimeStamp: null, // essential
      summaryTimeStamp: null, // essential
      dummyCity: [
        {
          room: "vass",
          name: "cdx",
          country: "zjd",
          time: 224999,
        },
      ],
    };
  }
  getStateString = () => {
    const {
      mode,
      cities,
      countryIndexSelected,
      gameTimeStamp,
      startTimeStamp,
      summaryTimeStamp,
      currentTimes,
      initialTimes,
    } = this.state;
    return JSON.stringify({
      mode,
      cities,
      countryIndexSelected,
      gameTimeStamp,
      startTimeStamp,
      summaryTimeStamp,
      currentTimes,
      initialTimes,
    });
  };

  isBlocked = (time) => {
    return time >= GOODNIGHT && time <= GOODMORNING;
  };

  adjustStatus = () => {
    console.log("vass, adjusting dude!");
    if (this.state.mode !== ON_GAMING) {
      return;
    }

    let { blocked, selfBlocked, currentTimes, initialTimes, startTimeStamp } =
      this.state;

    for (let i = 0; i < blocked.length; i++) {
      currentTimes[i] =
        (initialTimes[i] + Date.now() - startTimeStamp) % END_OF_DAY;
    }

    for (let i = 0; i < blocked.length; i++) {
      blocked[i] = this.isBlocked(currentTimes[i]);
    }

    selfBlocked = blocked[this.state.countryIndexSelected];
    if (selfBlocked && !this.state.selfBlocked) {
      alert(
        "You are entering a blocking time. you won't be able to communicate with others."
      );
    } else if (!selfBlocked && this.state.selfBlocked) {
      alert(
        "You get out from the blocking time. Communication returns to normal. "
      );
    }
    this.setState({
      blocked,
      selfBlocked,
      currentTimes,
    });

    if (selfBlocked) {
      if (this.state.showDummyModal || this.state.renderVideo) {
        alert(
          "Your social media will be turned off, since you just entered a BLOCKING period"
        );
        this.setState({
          showDummyModal: false,
          showVideoModal: false,
          renderVideo: false,
        });
      }
    }
  };

  checkPrepareGame = async () => {
    let res = await axios.get("/initialize");
    res = res.data;
    console.log(res);
    let cities = JSON.parse(res.countries_string);
    if (res.prepareGame && this.state.mode === IDLE) {
      this.setState(
        {
          mode: PREPARE_GAME,

          cities,
        },
        () => {
          localStorage.setItem("PLAYER_backup", this.getStateString());
        }
      );
    }
  };
  determinePhase = (reference) => {
    if (reference.summaryTimeStamp !== null) {
      return POST_GAMING;
    } else if (reference.startTimeStamp !== null) {
      return ON_GAMING;
    } else if (reference.gameTimeStamp !== null) {
      return PREPARE_GAME;
    } else {
      return IDLE;
    }
  };

  async retrieveHistory() {
    /*
      mode, // 
      cities,
      countryIndexSelected,
      gameTimeStamp,
      startTimeStamp,
      summaryTimeStamp,
      currentTimes, //
      initialTimes //
    */
    if (!localStorage.getItem("PLAYER_backup")) {
      return;
    }
    const history = JSON.parse(localStorage.getItem("PLAYER_backup"));
    console.log(history);
    this.setState(
      {
        gameTimeStamp: history.gameTimeStamp,
        startTimeStamp: history.startTimeStamp,
        summaryTimeStamp: history.summaryTimeStamp,
        cities: history.cities,
      },
      () => {
        console.log("WOW::::::", this.state);
      }
    );

    let reference = await axios.get(`/gameTimes`);
    reference = reference.data;
    console.log(reference);

    let needChangeTime = false;
    if (reference.gameTimeStamp === null) {
      return;
    } else if (history.countryIndexSelected === -1) {
      if (reference.mode !== PREPARE_GAME) {
        return;
      }
    }
    if (history.gameTimeStamp === reference.gameTimeStamp) {
      if (history.mode === PREPARE_GAME) {
        if (this.determinePhase(reference) === PREPARE_GAME) {
          this.setState({
            mode: PREPARE_GAME,
            countryIndexSelected: history.countryIndexSelected,
          });
        } else if (this.determinePhase(reference) === ON_GAMING) {
          needChangeTime = true;
          this.setState({
            currentTimes: reference.currentTimes,
            initialTimes: reference.initialTimes,
            countryIndexSelected: history.countryIndexSelected,
          });
        } else {
          this.setState({
            mode: POST_GAMING,
            countryIndexSelected: history.countryIndexSelected,
          });
        }
      } else if (history.mode === ON_GAMING) {
        console.log("here it is...");
        if (this.determinePhase(reference) === ON_GAMING) {
          console.log("not get here???");
          needChangeTime = true;
          this.setState({
            currentTimes: reference.currentTimes,
            initialTimes: reference.initialTimes,
            countryIndexSelected: history.countryIndexSelected,
          });
        } else {
          this.setState({
            mode: POST_GAMING,
            countryIndexSelected: history.countryIndexSelected,
          });
        }
      } else if (history.mode === POST_GAMING) {
        this.setState({
          mode: POST_GAMING,
          countryIndexSelected: history.countryIndexSelected,
        });
      }

      if (needChangeTime) {
        this.recoverTime();
        this.setState({
          mode: ON_GAMING,
        });
      }
    } else {
      this.checkPrepareGame();
    }

    //localStorage.removeItem("PLAYER_backup")
  }
  recoverTime = async () => {
    console.log(this.state.cities);
    let arr = this.state.cities;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].time < 1000) {
        arr[i].time = arr[i].time * 75 * 1000;
      }
    }
    this.setState({
      cities: arr,
    });

    let currentTimes = [];
    let blocked = [];
    let initialTimes = [];
    let selfBlocked = true;

    const currTime = Date.now();
    let startTimeStamp = this.state.startTimeStamp;

    for (var i = 0; i < arr.length; i++) {
      var city = arr[i];
      console.log(city);
      currentTimes.push(city.time + currTime - startTimeStamp);
      initialTimes.push(city.time);
      console.log(currentTimes);
      if (this.isBlocked(city.time * 75 * 1000)) {
        blocked.push(true);
      } else {
        blocked.push(false);
      }
      if (blocked[this.state.countryIndexSelected]) {
        selfBlocked = true;
      } else {
        selfBlocked = false;
      }
    }

    let copy = this.state.cities;
    for (let i = 0; i < copy.length; i++) {
      copy[i].time = copy[i].time + currTime - startTimeStamp;
    }
    console.log("fixed time: ", copy);
    this.setState({
      blocked,
      currentTimes,
      initialTimes,
      citiesForWorldMap: copy,
    });
  };
  initializeTimes = async () => {
    console.log(this.state.cities);
    const res = await axios.get(`/gameTimes`);

    this.setState({
      gameTimeStamp: res.data.gameTimeStamp,
      startTimeStamp: res.data.startTimeStamp,
      summaryTimeStamp: res.data.summaryTimeStamp,
    });
    console.log(res);

    const arr = this.state.cities;
    let currentTimes = [];
    let blocked = [];
    let initialTimes = [];
    let selfBlocked = true;

    for (var i = 0; i < arr.length; i++) {
      var city = arr[i];
      console.log(city);
      currentTimes.push(city.time * 75 * 1000);
      initialTimes.push(city.time * 75 * 1000);
      console.log(currentTimes);
      if (this.isBlocked(city.time * 75 * 1000)) {
        blocked.push(true);
      } else {
        blocked.push(false);
      }
      if (blocked[this.state.countryIndexSelected]) {
        selfBlocked = true;
      } else {
        selfBlocked = false;
      }
    }

    let infos = this.state.cities;
    for (var j = 0; j < infos.length; j++) {
      infos[j].time = infos[j].time * 75 * 1000;
    }
    this.setState(
      {
        cities: infos,
        blocked,
        currentTimes,
        initialTimes,
        selfBlocked,
        citiesForWorldMap: infos,
        mode: ON_GAMING,
      },
      () => {
        localStorage.setItem("PLAYER_backup", this.getStateString());
      }
    );
  };

  addMessage = (message) => {
    console.log("[addMessage]", message);
    this.setState({ messages: [...this.state.messages, message] });
    console.log(this.state.messages.length);
  };

  async componentDidMount() {
    this.checkPrepareGame();
    this.retrieveHistory();

    socket.on("message", (message) => {
      message.outgoing = false;
      this.addMessage(message);
    });

    socket.on("PREPARE GAME", async (data) => {
      alert("A new game has been initialized by administrator.");
      this.setState({
        countryIndexSelected: -1,
      });
      const res = await axios.get(`/gameTimes`);

      this.setState(
        {
          gameTimeStamp: res.data.gameTimeStamp,
          startTimeStamp: res.data.startTimeStamp,
          summaryTimeStamp: res.data.summaryTimeStamp,
        },
        () => {
          localStorage.setItem("PLAYER_backup", this.getStateString());
        }
      );

      data = JSON.parse(data);
      if (this.props.auth.loginStatus !== LOGGED_IN_AS_ADMIN) {
        this.setState(
          {
            mode: PREPARE_GAME,

            cities: data,
          },
          () => {
            localStorage.setItem("PLAYER_backup", this.getStateString());
          }
        );
        console.log(this.state);
      }
    });

    this.intervalID = setInterval(() => this.adjustStatus(), 444);
    socket.on("START GAME", async (data) => {
      console.log("dude");
      this.initializeTimes();
    });

    socket.on("END_GAME", (data) => {
      alert("The game now ends. please join a summary video conference below.");
      this.setState(
        {
          mode: POST_GAMING,
        },
        () => {
          localStorage.setItem("PLAYER_backup", this.getStateString());
        }
      );
    });
  }

  handleOpenChat = (i, j) => {
    if (i < j) {
      this.setState({
        first: i + "",
        second: j + "",
        showDummyModal: true,
      });
    } else {
      this.setState({
        first: j + "",
        second: i + "",
        showDummyModal: true,
      });
    }
  };

  handleOpenVideo = async (i, j) => {
    if (i < j) {
      this.setState({
        first: i + "",
        second: j + "",
      });
    } else {
      this.setState({
        first: j + "",
        second: i + "",
      });
    }
    this.setState({
      renderVideo: true,
    });
  };
  handleDummyOpen = () => {
    this.setState({
      showDummyModal: true,
    });
  };
  handleDummyClose = () => {
    this.setState({
      showDummyModal: false,
    });
  };
  renderChatModal = (first, second, name) => {
    return (
      <div>
        <button onClick={this.handleDummyOpen}>Open Modal</button>
        <Modal
          show={this.state.showDummyModal}
          onHide={this.handleDummyClose}
          size="xl"
        >
          <Modal.Header closeButton>Dummy Header</Modal.Header>
          <Modal.Body>
            <ChatWindow
              city={this.state.cities[this.state.countryIndexSelected]}
              cities={this.state.cities}
              first={this.state.cities[Number(first)]}
              second={this.state.cities[Number(second)]}
              name={name}
              blocked={this.state.selfBlocked}
              messages={this.state.messages}
              addMessage={this.addMessage}
            />
          </Modal.Body>
          <Modal.Footer>Dummy Footer</Modal.Footer>
        </Modal>
      </div>
    );
  };

  renderIdle() {
    return (
      <Container>
        <h2>Welcome to the game!</h2>
        <h4>Please wait for administor to start a game.</h4>
      </Container>
    );
  }

  unJoin = () => {
    this.props.roomUnJoin(this.state.countryIndexSelected);
    this.setState(
      {
        countryIndexSelected: -1,
      },
      () => {
        localStorage.setItem("PLAYER_backup", this.getStateString());
      }
    );
  };
  countrySelect = (index) => {
    this.setState(
      {
        countryIndexSelected: index,
      },
      () => {
        localStorage.setItem("PLAYER_backup", this.getStateString());
      }
    );
    this.props.roomJoin(index);
  };
  formatTime = (sec) => {
    const rawSecond = Math.round(sec / 1000);
    let hour = Math.floor(rawSecond / 75);
    let second = rawSecond % 75;
    second = Math.round((second * 60) / 75);
    if (second === 60) {
      second = 0;
      hour++;
      if (hour === 24) {
        hour = 0;
      }
    }

    let str = "" + hour + ":";
    if (second < 10) {
      str += "0";
    }
    str += second;

    return str;
  };
  renderOnGaming() {
    let ind = this.state.countryIndexSelected;
    let myCity = this.state.cities[ind];

    return (
      <Container style={{ marginTop: "7%", alignContent: "center" }}>
        <h3>
          Your room: {myCity.room}, {myCity.name}, {myCity.country}
        </h3>
        <Box display="flex" flexDirection="row" style={{ width: "100%" }}>
          <WorldClock players={this.state.citiesForWorldMap} />
          <Box style={{ width: "5%" }}>
            {this.state.cities.map((city, i) => (
              <div
                key={i}
                style={{
                  fontSize: "1.2rem",
                  height: 95,
                  paddingTop: 18,
                }}
              >
                {this.state.countryIndexSelected !== i && (
                  <IconButton
                    style={{
                      paddingTop: 6,
                      color: "white",
                    }}
                    disabled={this.state.selfBlocked || this.state.blocked[i]}
                    onClick={() =>
                      this.handleOpenChat(this.state.countryIndexSelected, i)
                    }
                  >
                    <ChatIcon>Chat</ChatIcon>
                  </IconButton>
                )}
              </div>
            ))}
          </Box>
        </Box>

        <div>
          <Modal
            show={this.state.showDummyModal}
            onHide={this.handleDummyClose}
            size="xl"
          >
            <Modal.Header style={{ textAlign: "center" }} closeButton>
              Chat window
            </Modal.Header>
            <Modal.Body>
              <ChatWindow
                city={this.state.cities[this.state.countryIndexSelected]}
                cities={this.state.cities}
                first={this.state.cities[Number(this.state.first)]}
                second={this.state.cities[Number(this.state.second)]}
                name={
                  this.state.cities[this.state.countryIndexSelected].room + ""
                }
                blocked={this.state.selfBlocked}
                messages={this.state.messages}
                addMessage={this.addMessage}
              />
            </Modal.Body>
          </Modal>
        </div>
        <br />
        <br />
      </Container>
    );
  }

  renderPrepareGame() {
    return (
      <Container style={{ fontSize: "35px" }}>
        <h2>Your administrator has initialized a new game. </h2>
        <h3
          style={{
            color: this.state.countryIndexSelected === -1 ? "white" : "yellow",
          }}
        >
          {this.state.countryIndexSelected === -1
            ? "Please choose which country/city you would like to join: "
            : `Your selection: Room ${this.state.cities[
                this.state.countryIndexSelected
              ].room.toUpperCase()} in ${this.state.cities[
                this.state.countryIndexSelected
              ].name.toUpperCase()}, ${this.state.cities[
                this.state.countryIndexSelected
              ].country.toUpperCase()}`}
        </h3>
        {this.state.countryIndexSelected !== -1 && (
          <h3 style={{ color: "white" }}>
            Please wait administrator to formally start the game
          </h3>
        )}

        {this.state.cities.map((city, i) => (
          <div key={i}>
            Room {city.room.toUpperCase()} in {city.name.toUpperCase()},{" "}
            {city.country.toUpperCase()} where the initial time is {city.time}
            :00.
            <button
              className="btn btn-primary"
              disabled={this.state.countryIndexSelected !== -1}
              style={{ marginLeft: "20px" }}
              onClick={() => this.countrySelect(i)}
            >
              Join
            </button>
          </div>
        ))}

        {this.state.countryIndexSelected !== -1 && (
          <Button onClick={this.unJoin}>Revert your decision</Button>
        )}
      </Container>
    );
  }

  renderPostGaming = () => {
    return (
      <Container fluid>
        <h2>The game has ended.</h2>

        <Link to={"/"}>
          <Button
            onClick={() => {
              localStorage.removeItem("PLAYER_backup");
              this.props.history.push("/");
            }}
          >
            Leave the game
          </Button>
        </Link>
      </Container>
    );
  };
  render() {
    if (this.state.mode === IDLE) {
      return this.renderIdle();
    } else if (this.state.mode === PREPARE_GAME) {
      return this.renderPrepareGame();
    } else if (this.state.mode === ON_GAMING) {
      return this.renderOnGaming();
    } else if (this.state.mode === POST_GAMING) {
      return this.renderPostGaming();
    } else {
      return this.renderDummyModal();
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(mapStateToProps)(Game));
//export default Game;
