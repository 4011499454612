import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import './Landing.css';


class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return(
      <Container>
        <h1>Welcome to Cross Cultural Simulation.</h1>
        <h3>Please log in as an administrator or a student.</h3>
        <br/>
        <Button href="game" variant="success">Student</Button>{' '}
        <Button href="nameform" variant="success">Admin</Button>{' '}
        <br/>
        <br/>
        <Button variant="danger" onClick={() => {
          localStorage.removeItem("ADMIN_backup")
          localStorage.removeItem("PLAYER_backup")
        }}>Clear the buffer</Button>
      </Container>
      
    )
  }
}

export default Landing;
