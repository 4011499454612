import React from 'react';
import  { Redirect } from 'react-router-dom'

class LoginAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      redirect: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
      // alert(this.state.value)
    if (this.state.value === "fighton") {
      this.setState({ redirect: true });
    } else {
      alert('Wrong Password. Please contact USC Marshall Experiental Learning Center');
    }
    event.preventDefault();
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/administrator" />;
    }
    return (
      <form style={{paddingTop: "400px"}} onSubmit={this.handleSubmit}>
        <label style={{fontSize: "18px" }}>
          PASSWORD:
          <input type="text" class="form-control" value={this.state.value} onChange={this.handleChange} />
        </label>
        <input type="submit" class="btn btn-success" value="Submit" />
      </form>

      
    );
  }

}
export default LoginAdmin;

