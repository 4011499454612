import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import './App.css'

import TestAPI from './TestAPI'
import Signup from './Signup'
import Game from './Game'
import Landing from './Landing'
import Administrator from './Administrator'
import NameForm from './NameForm'

import io from 'socket.io-client'
import getStore from './store'
import { Provider } from 'react-redux'
import { LOGGED_IN_AS_ADMIN } from './CONSTANTS.js'
import { PORT } from './CONSTANTS.js'
import VideoComponent from './VideoComponent'
const store = getStore()
let socket = io(PORT)

class App extends Component {
    constructor(props) {
        super(props)
        console.log('store: ', store.getState().auth)
        this.state = {
            apiResponse: '',
        }

        store.subscribe(() => {
            console.log('changes made to redux store.')
        })
    }

    roomJoin = (roomIndex) => {
        socket.emit('room join', roomIndex)
    }

    roomUnJoin = (roomIndex) => {
        socket.emit('room unjoin', roomIndex)
    }

    componentDidMount() {
        socket.on(`server:event`, (data) => {
            console.log(data)
        })

        socket.on('START GAME', (data) => {
            const loginStatus = store.getState().auth.loginStatus
            if (loginStatus !== LOGGED_IN_AS_ADMIN) {
                //alert("administar notifies me that game should start.")
            }
        })
    }

    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div className="App">
                        <Navbar bg="light" expand="lg">
                            <Navbar.Brand>
                                Cross Cultural Virtual Teams
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="mr-auto"></Nav>
                            </Navbar.Collapse>
                        </Navbar>

                        <Switch>
                            <Route exact path="/login" children={<login />} />
                            <Route exact path="/signup" children={<Signup />} />
                            <Route
                                exact
                                path="/testAPI"
                                children={<TestAPI />}
                            />
                            <Route
                                exact
                                path="/game"
                                children={
                                    <Game
                                        roomJoin={this.roomJoin}
                                        roomUnJoin={this.roomUnJoin}
                                    />
                                }
                            />
                            <Route exact path="/" children={<Landing />} />
                            <Route
                                exact
                                path="/administrator"
                                children={<Administrator />}
                            />
                            <Route
                                exact
                                path="/nameform"
                                children={<NameForm />}
                            />
                            <Route
                                exact
                                path="/video/:first/:second/:name"
                                children={<VideoComponent />}
                            />
                        </Switch>
                    </div>
                </Router>
            </Provider>
        )
    }
}

export default App
