import React, { Component } from 'react';

class ClockMinute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
      timestamp: Date.now(),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      //this is one second
      // 1000
      //(1 hour = 1.25 minutes)
      (1.25 * 1000) / 100
    );
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: parseInt(
        ((((Date.now() - this.state.timestamp) / 1000) * 48) / 60) % 60,
        10
      ),
    });
  }
  render() {
    if (this.state.time < 10) {
      return <p className="App-clock">0{this.state.time}</p>;
    } else {
      return <p className="App-clock">{this.state.time}</p>;
    }
  }
}
export default ClockMinute;
