import React, { Component } from 'react';
import {Button, Form} from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withRouter } from "react-router-dom";

import { LOGGED_IN_AS_STUDENT } from './CONSTANTS.js';
import { setLogInState } from './store';
import { connect } from 'react-redux';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    let postUrl = process.env.PORT+"/signup";
    alert('A user signup was submitted: ' + this.state);
    event.preventDefault();
    console.log(this.state);
    axios
      .post(postUrl, this.state)
      .then(response => {
        if(response.data === "success") {
          console.log("signup successful!: \n" + response.data);
          this.props.setLogInState(LOGGED_IN_AS_STUDENT);
          this.props.history.push('/game');
        } else {
          alert("signup error");
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  render() {
    return(
      <div>
        <h2>Signup</h2>

        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <Form onSubmit={this.handleSubmit} method="POST">
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control name="username" type="email" placeholder="Enter email" onChange={this.handleChange} value={this.state.email} />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control name="password" type="password" placeholder="Password" onChange={this.handleChange} value={this.state.pw} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setLogInState: status => dispatch(setLogInState(status))
})

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Signup));