import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Clock from "./Clock";
import ClockMinute from "./ClockMinute";
import "./Worldmap.css";
import "bootstrap/dist/css/bootstrap.min.css";

const MORNING_HOUR = 7;
const END_HOUR = 0;

class Worldmap extends Component {
  constructor(props) {
    super(props);
    this.state = { time: 0, timestamp: Date.now() };
  }

  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      //this is one second
      // 1000
      //(1 hour = 1.25 minutes)
      (1.25 * 60 * 1000) / 10000
    );
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  // minutes
  tick() {
    this.setState({
      time:
        parseInt(((this.props.players[0].time % 75000) / 75000) * 60, 10) +
        parseInt((((Date.now() - this.state.timestamp) / 1000) * 48) / 60, 10),
    });
  }

  createTable = () => {
    let table = [];
    // Outer loop to create parent
    for (let i = 0; i < this.props.players.length; i++) {
      let children = [];
      //Inner loop to create children
      children.push(<td>{i + 1}</td>);
      children.push(<td>{this.props.players[i].name.toUpperCase()}</td>);
      children.push(<td>{this.props.players[i].country.toUpperCase()}</td>);

      var currHour = parseInt(
        (this.props.players[i].time / 75 / 1000) % 24,
        10
      );

      if (currHour < MORNING_HOUR && currHour >= END_HOUR) {
        children.push(
          <td>
            {<Clock time={currHour} />}:<ClockMinute />
          </td>
        );
      } else {
        children.push(
          <td>
            {<Clock time={currHour} />}:<ClockMinute />
          </td>
        );
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };

  blockStatus(hour) {
    if (hour < MORNING_HOUR && hour >= END_HOUR) {
      return "Yes";
    } else {
      return "No";
    }
  }

  createTimeZone = () => {
    let table = [];
    // Outer loop to create parent
    for (let i = 0; i < this.props.players.length; i++) {
      let children = [];
      var passedHour = parseInt(
        (this.props.players[i].time / 75 / 1000) % 24,
        10
      );
      while (passedHour > 23) {
        passedHour -= 24;
      }

      //Inner loop to create children
      for (let j = 0; j < 24; j++) {
        let currTime = passedHour + j;
        while (currTime > 23) {
          currTime = currTime - 24;
        }

        var currHour = parseInt(this.state.time / 60, 10);
        while (currHour > 23) {
          currHour -= 24;
        }

        var currMinutes = this.state.time % 60;

        const displayTime = currTime + 1;

        if (currTime < MORNING_HOUR && currTime >= END_HOUR) {
          if (j === currHour) {
            children.push(
              <div
                style={{
                  height: 35,
                  borderRadius: 5,
                  fontSize: 13,
                }}
                class="nightTime selected"
              >
                {displayTime}
              </div>
            );
          } else {
            children.push(
              <div
                style={{
                  height: 35,
                  borderRadius: 5,
                  fontSize: 13,
                }}
                class="nightTime"
              >
                {displayTime}
              </div>
            );
          }
        } else {
          if (j === currHour) {
            children.push(
              <div
                style={{
                  height: 35,
                  borderRadius: 5,
                  fontSize: 13,
                }}
                class="dayTime selected"
              >
                {displayTime}
              </div>
            );
          } else {
            children.push(
              <div
                style={{
                  height: 35,
                  borderRadius: 5,
                  fontSize: 13,
                }}
                class="dayTime"
              >
                {displayTime}
              </div>
            );
          }
        }
      }

      currMinutes = parseInt(this.state.time % 60, 10);

      // what the fuck
      let h = currHour + passedHour;
      while (h > 23) {
        h -= 24;
      }
      ++h;

      table.push(
        <h2
          style={{
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
          }}
          class="timeZoneName"
        >
          {this.props.players[i].name.toUpperCase()}
          {", "}
          {this.props.players[i].country.toUpperCase()}
          {", Local Time: "}
          <span style={{ color: "red" }}>
            {h}:
            {currMinutes % 60 < 10
              ? "0" + (currMinutes % 60)
              : currMinutes % 60}
          </span>
          {", Blocked: "}
          {this.blockStatus((currHour + passedHour) % 24)}
        </h2>
      );
      table.push(
        <div
          class="flex-container"
          style={{
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
          }}
        >
          {children}
        </div>
      );
    }
    return table;
  };

  render() {
    return (
      <Box style={{ width: "90%" }}>
        {this.createTimeZone()}
        <br />
        <br />
      </Box>
    );
  }
}

export default Worldmap;
