import { Component } from 'react';

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.time,
      timestamp: Date.now(),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      //this is one second
      // 1000
      //(1 hour = 1.25 minutes)
      (1.25 * 60 * 1000) / 100
    );
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: parseInt(
        ((((Date.now() - this.state.timestamp) / 1000) * 48) / 3600 +
          this.props.time) %
          24,
        10
      ),
    });
  }
  render() {
    return (
      // <p className="App-clock">
      //   {this.state.time}
      // </p>
      this.state.time
    );
  }
}
export default Clock;
