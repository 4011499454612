import React, { Component } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { setLogInState } from "./store";
import io from "socket.io-client";
import { connect } from "react-redux";
import { LOGGED_IN_AS_ADMIN } from "./CONSTANTS.js";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import axios from "axios";
import TwilioChat from "twilio-chat";
import { withRouter } from "react-router-dom";
import { PORT } from "./CONSTANTS.js";

import WorldClock from "./Worldmap";

const endpoint = PORT;

let socket = io(endpoint);

const PREPARING_GAME = 0;
const WAITING_PLAYERS = 1;
const ON_GAMING = 2; // game in progress
const POST_GAMING = 3;

let cityList = [
  ["Auckland", "New Zealand", ""],
  ["Cebu City", "Philippines", "tl"],
  ["Mumbai", "India", "hi"],
  ["Istanbul", "Turkey", "tr"],
  ["Marrakesh", "Morocco", "ar"],
  ["Fortaleza", "Brazil", "pt"],
  ["Quito", "Ecuador", "es"],
  ["Los Angeles", "USA", ""],
];

class Administrator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: -1, // this indicates the game status. for now just call it 1.
      roomJoinData: [],

      // state variables when preparing the game:
      addedCities: [], // each should be {name: string, country: string, language: string}

      countryName: "",
      cityName: "",
      languageName: "",

      cityTime: "",

      roomName: "",
      initializing: false,
      // twilio stuff...
      chatClient: null,
      channel: null,
      token: null,
      gameTimeStamp: null,

      startTimeStamp: null,

      isTranslating: false,
    };
  }

  toggleTranslate = () => {
    this.setState({ isTranslating: !this.state.isTranslating });
  };

  createRooms = async () => {
    const list = this.state.addedCities;


    for (let i = 0; i < list.length; i++) {
      for (let j = i + 1; j < list.length; j++) {
        let name1 = "" + i;
        let name2 = "" + j;

        let roomName = name1 + "&" + name2;
        let chatClient = this.state.chatClient;

        chatClient
          .getChannelByUniqueName(roomName)
          .catch((err) => {
            chatClient
              .createChannel({
                uniqueName: roomName,
                friendlyName: "dummy",
              })
              .catch((err) => {});
          });
      }
    }
  };

  deleteRooms = () => {
    axios.delete(`/channels/num=${this.state.addedCities.length}`);
  };
  startGame = async () => {
    for (let i = 0; i < this.state.roomJoinData.length; i++) {
      if (this.state.roomJoinData[i] !== 1) {
        alert("warning: not all room are assigned.");
        break;
      }
    }
    await this.setState({
      initializing: true,
    });
    await this.createRooms();
    await axios.get(`/waiting`);
    await this.setState({
      initializing: false,
    });

    let addedCities = this.state.addedCities;
    for (var i = 0; i < addedCities.length; i++) {
      addedCities[i].time = addedCities[i].time * 75 * 1000;
    }
    this.setState(
      {
        addedCities,
        mode: ON_GAMING,
        startTimeStamp: Date.now(),
      },
      () => {
        localStorage.setItem("ADMIN_backup", this.getStateString());
        socket.emit("startTimeStamp", this.state.startTimeStamp);
      }
    );
    socket.emit("Admin_StartGame", "dummy");
  };

  onRoom = (e) => {
    const room = e.target.value;
    this.setState(() => ({
      roomName: room,
    }));
  };
  onCountry = (e) => {
    const country = e.target.value;
    this.setState(() => ({
      countryName: country,
    }));
  };
  onCity = (e) => {
    const city = e.target.value;
    this.setState(() => ({
      cityName: city,
    }));
  };
  onLanguage = (e) => {
    const language = e.target.value;
    this.setState(() => ({
      languageName: language,
    }));
  };
  onTime = (e) => {
    const time = e.target.value;
    this.setState(() => ({
      cityTime: time,
    }));
  };
  addCity = () => {
    const { roomName, cityName, countryName, languageName, cityTime } =
      this.state;
    if (
      !(
        roomName.trim() &&
        cityName.trim() &&
        countryName.trim() &&
        languageName.trim() &&
        cityTime.trim()
      )
    ) {
      alert("You cannot leave any of these values empty.");
      return;
    }
    let currTime = parseInt(cityTime);
    if (isNaN(currTime) || currTime > 23 || currTime < 0) {
      alert("Please input a Integer between 0 to 23 for your time.");
      return;
    }

    const lang =
      this.state.languageName === "" ? null : this.state.languageName;

    var joined = this.state.addedCities.concat({
      room: this.state.roomName,
      name: this.state.cityName,
      country: this.state.countryName,
      language: lang,
      time: currTime,
    });

    this.setState({
      addedCities: joined,
      countryName: "",
      cityName: "",
      cityTime: "",
      languageName: "",
      roomName: "",
    });
  };

  getStateString = () => {
    /*
    mode: PREPARING_GAME, // this indicates the game status. for now just call it 1. 
    roomJoinData: [],

    // state variables when preparing the game: 
    addedCities: [], // each should be {name: string, country: string}
    
    countryName: "",
    cityName: "",
    cityTime: "",

    roomName: "",
    initializing: false,
    // twilio stuff...
    chatClient: null,
    channel: null,
    token: null,
    gameTimeStamp: null*/
    let obj = {};
    obj.mode = this.state.mode;
    obj.addedCities = this.state.addedCities;
    obj.gameTimeStamp = this.state.gameTimeStamp;
    obj.startTimeStamp = this.state.startTimeStamp;
    return JSON.stringify(obj);
  };
  submitPrepareGame = async () => {
    if (this.state.addedCities.length === 0) {
      alert("You didn't add any city.");
      return;
    }
    await axios.post(`/submitPrepareGame`, {
      isTranslating: this.state.isTranslating,
      cities: this.state.addedCities,
    });

    const gameTimeStamp = Date.now();
    this.setState(
      {
        mode: WAITING_PLAYERS,
        roomJoinData: new Array(this.state.addedCities.length).fill(0),
        gameTimeStamp,
      },
      () => {
        localStorage.setItem("ADMIN_backup", this.getStateString());
        socket.emit("gameTimeStamp", gameTimeStamp);
      }
    );
  };

  resetCities = () => {
    this.setState({
      addedCities: [],
    });
  };

  onChangeDropdown = (city) => {
    this.setState(() => ({
      countryName: cityList[city][0],
      cityName: cityList[city][1],
      languageName: cityList[city][2],
    }));
  };

  renderPreparingGame = () => {
    return (
      // <ReactModal isOpen={true}>

      <Container style={{ textAlign: "center", marginTop: "100px" }}>
        <h1>Prepare your game</h1>

        <h3>Rooms added so far:</h3>
        {this.state.addedCities.length === 0
          ? "Country list is empty"
          : this.state.addedCities.map((city, i) => (
              <div key={i}>
                {"Room " + city.room.toUpperCase()}
                {" in " + city.name.toUpperCase()}, {city.country.toUpperCase()}
                , {" where the initial time is " + city.time + ":00"}
              </div>
            ))}

        <br />
        <br />
        <h3>Add your country: </h3>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text>
              Room, Country, City, Language, Initial Time
            </InputGroup.Text>
          </InputGroup.Prepend>
          <DropdownButton
            as={InputGroup.Prepend}
            variant="warning"
            title="Cities & Countries"
            id="input-group-dropdown-1"
          >
            <Dropdown.Item onClick={() => this.onChangeDropdown(0)}>
              Auckland, New Zealand
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.onChangeDropdown(1)}>
              Cebu City, Philippines
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.onChangeDropdown(2)}>
              Mumbai, India
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.onChangeDropdown(3)}>
              Istanbul, Turkey
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.onChangeDropdown(4)}>
              Marrakesh, Morocco
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.onChangeDropdown(5)}>
              Fortaleza, Brazil
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.onChangeDropdown(6)}>
              Quito, Ecuador
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.onChangeDropdown(7)}>
              Los Angeles, CA, USA
            </Dropdown.Item>
          </DropdownButton>
          <FormControl
            value={this.state.roomName}
            onChange={this.onRoom}
            placeholder="Room Name"
          />
          <FormControl
            value={this.state.countryName}
            onChange={this.onCountry}
            placeholder="Country"
          />
          <FormControl
            value={this.state.cityName}
            onChange={this.onCity}
            placeholder="City"
          />

          {this.state.isTranslating === true && (
            <FormControl
              disabled
              value={this.state.languageName}
              onChange={this.onLanguage}
              placeholder="Language"
            />
          )}

          <FormControl
            value={this.state.cityTime}
            onChange={this.onTime}
            placeholder={"0-23"}
          />
        </InputGroup>

        <Button variant="primary" block onClick={this.addCity}>
          Add Country City Pair
        </Button>

        <Button
          variant={this.state.isTranslating ? "primary" : "light"}
          block
          onClick={this.toggleTranslate}
        >
          Turn {this.state.isTranslating ? "Off" : "On"} Translation
        </Button>

        <Button
          variant="primary"
          block
          onClick={() => {
            let url = "https://www.timeanddate.com/worldclock/";
            var win = window.open(url, "_blank");
            win.focus();
          }}
        >
          Check Real World Clocks
        </Button>

        <Button variant="danger" block onClick={this.resetCities}>
          Reset Cities
        </Button>
        <br />
        <Button
          size="lg"
          variant="success"
          block
          onClick={this.submitPrepareGame}
        >
          Prepare Your Game!
        </Button>
      </Container>
    );
  };

  renderWaitingPlayers = () => {
    return (
      <Container style={{ textAlign: "center" }}>
        <h2>Currently Waiting Players:</h2>
        <h2>monitor the room usages below.</h2>
        {this.state.roomJoinData.map((count, i) => (
          <div key={i}>
            {"Room " + this.state.addedCities[i].room.toUpperCase()} in{" "}
            {this.state.addedCities[i].name.toUpperCase()},
            {" " + this.state.addedCities[i].country.toUpperCase()}:{" "}
            <strong>{count}</strong> player(s) Joined.
          </div>
        ))}
        <Button
          onClick={this.startGame}
          style={{ marginTop: "10px" }}
          disabled={this.state.initializing}
        >
          {this.state.initializing
            ? "The game is being initialized... please hang tight"
            : "Start Game"}
        </Button>
      </Container>
    );
  };

  endGame = () => {
    this.deleteRooms();
    socket.emit("Admin_endgame", "dummy");
    this.setState(
      {
        mode: POST_GAMING,
      },
      () => {
        localStorage.setItem("ADMIN_backup", this.getStateString());
      }
    );
  };
  renderOnGaming = () => {
    return (
      <Container style={{ textAlign: "center" }}>
        <h3>Game is now in progress!</h3>
        <hr />
        <Button onClick={this.endGame}>End the game now!</Button>
        <WorldClock players={this.state.addedCities} />
      </Container>
    );
  };

  renderPostGaming = () => {
    return (
      <Container fluid style={{ textAlign: "center" }}>
        <h3>The game is over.</h3>

        <Button
          onClick={() => {
            localStorage.removeItem("ADMIN_backup");
            socket.emit("GAME_END", "dummy");
            this.props.history.push("/");
          }}
        >
          Leave the game
        </Button>
      </Container>
    );
  };
  async componentDidMount() {
    /*
        chatClient: null,
        channel: null,
        token: null

    */

    // SETTING UP THE TWILIO:
    let conn = `/token/name=ADMIN`;
    console.log("URI for token:", conn);
    const res = await axios.get(conn);
    const token = res.data;
    console.log("TOKEN: ", token);

    const chatClient = await new TwilioChat(token.jwt);
    console.log(chatClient);

    this.setState({
      chatClient,
    });

    this.props.dispatch(setLogInState(LOGGED_IN_AS_ADMIN));
    /*
    setTimeout(() => {
      
    }, 2000)*/
    console.log(this.props.auth);
    socket.on(`server:event`, (data) => {
      console.log(data);
    });

    socket.on("roomJoinRequest", (data) => {
      console.log("WTF");
      //salert("there is a person joining room"+data);
      let tempData = this.state.roomJoinData;
      tempData[data]++;
      this.setState({
        roomJoinData: tempData,
      });
    });

    socket.on("roomUnJoinRequest", (data) => {
      let tempData = this.state.roomJoinData;
      tempData[data]--;
      this.setState({
        roomJoinData: tempData,
      });
    });

    /*
      let obj = {}
      obj.mode = this.state.mode
      obj.addedCities= this.state.addedCities
      obj.gameTimeStamp = this.state.gameTimeStamp
      obj.startTimeStamp = this.state.startTimeStamp
      return JSON.stringify(obj);
    */

    let savedData = JSON.parse(localStorage.getItem("ADMIN_backup"));
    if (savedData) {
      console.log(savedData);

      const { mode, addedCities, gameTimeStamp, startTimeStamp } = savedData;
      this.setState({
        addedCities,
        gameTimeStamp,
        startTimeStamp,
      });
      if (savedData.mode === WAITING_PLAYERS) {
        const res = await axios.get(`/joinData`);
        this.setState({
          roomJoinData: res.data,
        });
      }

      if (mode === ON_GAMING) {
        let arr = this.state.addedCities;
        for (let i = 0; i < arr.length; i++) {
          arr[i].time += Date.now() - startTimeStamp;
        }
        this.setState({
          addedCities: arr,
        });
      }
      this.setState({
        mode,
      });
    } else {
      this.setState({
        mode: PREPARING_GAME,
      });
    }
  }

  render() {
    if (this.state.mode === PREPARING_GAME) {
      // return <ReactModal isOpen={true}>this.renderPreparingGame()</ReactModal>
      // return <Redirect to='/'/>
      return this.renderPreparingGame();
    } else if (this.state.mode === WAITING_PLAYERS) {
      return this.renderWaitingPlayers();
    } else if (this.state.mode === ON_GAMING) {
      return this.renderOnGaming();
    } else if (this.state.mode === POST_GAMING) {
      return this.renderPostGaming();
    } else {
      return <h1>Loading....</h1>;
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(mapStateToProps)(Administrator));
