import React, { Component } from 'react';
import Worldmap from './Worldmap'
import './App.css';

class TestAPI extends Component {

  // --- begin setup React connection --- zhuoweiz
  constructor(props) {
    super(props);
    this.state = {
      players : [
        {
          name : 'Beijing',
          country: 'China',
          time: 10
        },
        {
          name : 'Los Angeles',
          country: 'USA',
          time: 3
        },
        {
          name : 'London',
          country: 'UK',
          time: 21
        },
        {
          name : 'Paris',
          country: 'France',
          time: 13
        },
        {
          name : 'Lima',
          country: 'Peru',
          time: 1
        }
      ]
    }
    // this.state = { apiResponse: "" };
  }
  callAPI() {
    let url = process.env.PORT + "/testAPI";
    fetch(url)
        .then(res => res.text())
        .then(res => this.setState({ apiResponse: res }));
  }
  componentWillMount() {
    this.callAPI();
  }
  // --- end setup React connection ---

  render() {
    return (
        <div className="App">
          <header className="App-header">
            {/* <h1>401 Project React-Express linkage test page</h1> */}
            <Worldmap players={this.state.players}/>
            {/* <p className="App-intro">{this.state.apiResponse}</p> */}
          </header>
        </div>
    );
  }
}

export default TestAPI;
